import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  questionInput: {
    maxWidth: 600,
    margin: "auto",
  },
}));

const QuestionAnalyzerSubmitComponent = (props) => {
  const classes = useStyles();
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedDomain, setSelectedDomain] = useState("");
  const [questionText, setQuestionText] = useState("");
  const [answerAText, setAnswerAText] = useState("");
  const [answerBText, setAnswerBText] = useState("");
  const [answerCText, setAnswerCText] = useState("");
  const [answerDText, setAnswerDText] = useState("");
  const [answerEText, setAnswerEText] = useState("");
  const [gradeItems, setGradeItems] = useState(["3", "4", "5", "6", "7", "8"]);
  const [domainItems, setDomainItems] = useState([
    "Algebra",
    "Expressions and Equations",
    "Functions",
    "Geometry",
    "Measurement and Data",
    "Number and Operations - Fractions",
    "Number and Operations in Base Ten",
    "Operations and Algebraic Thinking",
    "Ratios and Proportional Relationships",
    "Statistics and Probability",
    "The Number System",
  ]);
  const [loading, setLoading] = useState(false);

  const submitQuestion = () => {
    setLoading(true);

    const data = {
      grade: selectedGrade,
      domain: selectedDomain,
      question: questionText,
      answer_a: answerAText,
      answer_b: answerBText,
      answer_c: answerCText,
      answer_d: answerDText,
      answer_e: answerEText,
    };

    axios
      .post("https://math-test-analyzer.herokuapp.com/api/v1/analyze", data)
      .then((res) => {
        setLoading(false);
        props.onResult(res.data.results);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const gradeElements = gradeItems.map((item) => {
    return (
      <MenuItem key={item} value={item}>
        Grade Level {item}
      </MenuItem>
    );
  });

  const domainElements = domainItems.map((item) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    );
  });

  return (
    <div>
      <Card className={classes.questionInput}>
        <CardContent>
          <Typography variant="h3">Math Item Parse</Typography>
          <Box m={2} />
          <Typography>
            Hello! Please select the grade and math domain you intend for your
            question. Next, paste your question and no more than 5 answer
            choices in the boxes below. Please be aware Math Item Parse is still
            a prototype. Ultimately, this should be viewed as a tool to make
            your questions easier to read, but you know your students best.{" "}
          </Typography>
          <Box m={2} />
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="select-grade-label">Grade Level</InputLabel>
            <Select
              labelId="select-grade"
              id="select-grade"
              value={selectedGrade}
              onChange={(event) => setSelectedGrade(event.target.value)}
              label="Grade Level"
              autoWidth={true}
            >
              {gradeElements}
            </Select>
          </FormControl>
          <Box m={2} />
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="select-domain-label">Domain</InputLabel>
            <Select
              labelId="select-domain"
              id="select-domain"
              value={selectedDomain}
              onChange={(event) => setSelectedDomain(event.target.value)}
              label="Domain"
              autoWidth={true}
            >
              {domainElements}
            </Select>
          </FormControl>
          <Box m={2} />
          <TextField
            required
            id="questionText"
            name="questionText"
            label="Question..."
            variant="outlined"
            value={questionText}
            onChange={(event) => setQuestionText(event.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                ev.preventDefault();
              }
            }}
            fullWidth
            multiline
          />
          <Box m={2} />
          <TextField
            required
            id="answerAText"
            name="answerAText"
            label="Answer A"
            variant="outlined"
            value={answerAText}
            onChange={(event) => setAnswerAText(event.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                ev.preventDefault();
              }
            }}
            fullWidth
            multiline
          />
          <Box m={2} />
          <TextField
            required
            id="answerABext"
            name="answerBText"
            label="Answer B"
            variant="outlined"
            value={answerBText}
            onChange={(event) => setAnswerBText(event.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                ev.preventDefault();
              }
            }}
            fullWidth
            multiline
          />
          <Box m={2} />
          <TextField
            required
            id="answerCText"
            name="answerCText"
            label="Answer C"
            variant="outlined"
            value={answerCText}
            onChange={(event) => setAnswerCText(event.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                ev.preventDefault();
              }
            }}
            fullWidth
            multiline
          />
          <Box m={2} />
          <TextField
            required
            id="answerDText"
            name="answerDText"
            label="Answer D"
            variant="outlined"
            value={answerDText}
            onChange={(event) => setAnswerDText(event.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                ev.preventDefault();
              }
            }}
            fullWidth
            multiline
          />
          <Box m={2} />
          <TextField
            id="answerEText"
            name="answerEText"
            label="Answer E"
            variant="outlined"
            value={answerEText}
            onChange={(event) => setAnswerEText(event.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                ev.preventDefault();
              }
            }}
            fullWidth
            multiline
          />
          <Box m={2} />
          <LoadingButton
            size="large"
            color="primary"
            variant="contained"
            loading={loading}
            onClick={(e) => submitQuestion()}
          >
            Submit
          </LoadingButton>
          {
            loading ? <Typography align="center">Thank you for your patience, this might take a few moments</Typography> : <div/>
          }
        </CardContent>
      </Card>
    </div>
  );
};

export default QuestionAnalyzerSubmitComponent;
