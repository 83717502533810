import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import QuestionAnalyzerResultComponent from "../components/QuestionAnalyzerResultComponent";
import QuestionAnalyzerSubmitComponent from "../components/QuestionAnalyzerSubmitComponent";

const QuestionAnalyzePage = () => {
  const [result, setResult] = useState(null);

  function handleAnalyzerClick(event) {
    event.preventDefault();
    setResult(null);
  }

  const getView = () => {
    if (result) {
      return (
        <React.Fragment>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link
              underline="hover"
              key="1"
              color="inherit"
              href="/"
              onClick={handleAnalyzerClick}
            >
              Math Item Parse
            </Link>
            <Typography key="3" color="text.primary">
              Results
            </Typography>
          </Breadcrumbs>
          <QuestionAnalyzerResultComponent results={result} />
        </React.Fragment>
      );
    } else {
      return (
        <QuestionAnalyzerSubmitComponent
          onResult={(componentResult) => setResult(componentResult)}
        />
      );
    }
  };

  return <Box sx={{ padding: 2 }}>{getView()}</Box>;
};

export default QuestionAnalyzePage;
