import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import React, { useState } from "react";

// const results = {
//   source: {
//     grade: 3,
//     domain: "Operations and Algebraic Thinking",
//     question: "Which pair of equations are related facts?",
//     answer_a: "54  /  6 = n and 6 x n = 54",
//     answer_b: "54  /  n = 6 and 54 + 6 = n",
//     answer_c: "n  /  2 = 12 and 12 - n = 2",
//     answer_d: "2  /  12 = n and n x 2 = 12",
//     answer_e: "",
//   },
//   readability_score: 0.07145454545454655,
//   word_count: 50,
//   polysemous: ["related", "equation"],
//   academic_words: ["equations"],
//   p_value: 1,
//   pos: {
//     CC: 11,
//     CD: 16,
//     DT: 0,
//     EX: 0,
//     FW: 0,
//     IN: 1,
//     JJ: 1,
//     JJR: 0,
//     JJS: 0,
//     LS: 0,
//     MD: 0,
//     NN: 2,
//     NNS: 3,
//     NNP: 0,
//     NNPS: 0,
//     PDT: 0,
//     POS: 0,
//     PRP: 0,
//     PRP$: 0,
//     RB: 0,
//     RBR: 0,
//     RBS: 0,
//     RP: 0,
//     SYM: 15,
//     TO: 0,
//     UH: 0,
//     VB: 0,
//     VBD: 0,
//     VBG: 0,
//     VBN: 0,
//     VBP: 1,
//     VBZ: 0,
//     WDT: 1,
//     WP: 0,
//     WP$: 0,
//     WRB: 0,
//     $: 0,
//     ",": 0,
//     ":": 0,
//   },
// };

const QuestionAnalyzerResultComponent = (props) => {
  // todo - create a new component
  const createReadability = () => {
    let operator_direction;
    if (
      props.results.source.grade == props.results.readability_score.toFixed(2)
    ) {
      operator_direction = "=";
    } else if (
      props.results.source.grade < props.results.readability_score.toFixed(2)
    ) {
      operator_direction = "<";
    } else {
      operator_direction = ">";
    }

    return (
      <React.Fragment>
        <Box m={2} />
        <Grid
          sx={{
            display: { xs: "block", md: "inline-flex" },
            margin: "auto",
          }}
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <TextField
              id="gradeLevel"
              name="gradeLevel"
              label="Selected Grade"
              variant="outlined"
              value={props.results.source.grade}
            />
          </Grid>
          <Grid item>
            <Typography
              sx={{
                textAlign: "center",
              }}
              variant="h5"
            >
              {operator_direction}
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              id="readabilityLevel"
              name="readabilityLevel"
              label="Readability"
              variant="outlined"
              value={props.results.readability_score.toFixed(2)}
            />
          </Grid>
        </Grid>
        <Box m={2} />
      </React.Fragment>
    );
  };

  const createWordCount = () => {
    let color = "success";
    if (props.results.word_count >= 50) {
      color = "error";
    }

    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align="left">
          <Chip label={props.results.word_count} color={color} />
        </TableCell>
        <TableCell align="left">{"Overall Word Count"}</TableCell>
        <TableCell align="left">
          {
            ">50 words: Warning! Long questions with large word counts are difficult for ELLs. Try to shorten/simplify if possible."
          }
        </TableCell>
      </TableRow>
    );
  };

  const createAcademicWords = () => {
    const words = props.results.academic_words.join(", ");

    let color = "success";
    if (props.results.academic_words.length > 0) {
      color = "warning";
    }

    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align="left">
          <Chip label={props.results.academic_words.length} color={color} />
        </TableCell>
        <TableCell align="left">{"Academic Words"}</TableCell>
        <TableCell align="left">
          <Box fontWeight="bold" display="inline">
            {words}
          </Box>
        </TableCell>
      </TableRow>
    );
  };

  const createPolysemousWords = () => {
    const words = props.results.polysemous.join(", ");

    let color = "success";
    if (props.results.polysemous.length > 0) {
      color = "warning";
    }

    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align="left">
          <Chip label={props.results.polysemous.length} color={color} />
        </TableCell>
        <TableCell align="left">{"Polysemous Words"}</TableCell>
        <TableCell align="left">
          <Box fontWeight="bold" display="inline">
            {words}
          </Box>
        </TableCell>
      </TableRow>
    );
  };

  const createPValue = () => {
    let level;
    switch (props.results.p_value) {
      case 0:
        level = "hard";
        break;
      case 1:
        level = "medium";
        break;
      default:
        level = "easy";
    }

    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align="left">
          <Chip
            sx={{ backgroundColor: "text.secondary" }}
            label={level}
            color="primary"
          />
        </TableCell>
        <TableCell align="left">{"P-Value"}</TableCell>
        <TableCell align="left">
          {"This is an estimate of how difficult this question will be."}
        </TableCell>
      </TableRow>
    );
  };

  const createDistinctNumbers = () => {
    let color = "success";
    if (props.results.pos.CD > 0) {
      color = "warning";
    }

    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align="left">
          <Chip label={props.results.pos.CD} color={color} />
        </TableCell>
        <TableCell align="left">{"Distinct Numbers"}</TableCell>
        <TableCell align="left">
          {
            "Warning! The more numbers you put in a question, the more difficult the question might be for ELLs."
          }
        </TableCell>
      </TableRow>
    );
  };

  const createNumberOfDeterminers = () => {
    let color = "success";
    if (props.results.pos.DT > 0) {
      color = "warning";
    }

    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align="left">
          <Chip label={props.results.pos.DT} color={color} />
        </TableCell>
        <TableCell align="left">{"Number of Determiners"}</TableCell>
        <TableCell align="left">
          <Typography>
            A determiner is a word like “the, a, an”. The more of these present,
            the more difficult the item might be for ELLs.
          </Typography>
          {props.results.pos.DT > 0 && (
            <Typography>
              {'>'}
              <Box fontWeight="bold" display="inline" bgcolor="warning">
                {` ${props.results.pos_words.DT.join(", ")}`}
              </Box>
            </Typography>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const createNumberOfPredeterminers = () => {
    let color = "success";
    if (props.results.pos.PDT > 0) {
      color = "warning";
    }

    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align="left">
          <Chip label={props.results.pos.PDT} color={color} />
        </TableCell>
        <TableCell align="left">{"Number of Predeterminers"}</TableCell>
        <TableCell align="left">
          {
            "A predeterminer is a word like “both, most, all.” The more of these present, the more difficult the item might be for ELLs."
          }
          {props.results.pos.PDT > 0 && (
            <Typography>
              {'>'}
              <Box fontWeight="bold" display="inline">
                {` ${props.results.pos_words.PDT.join(", ")}`}
              </Box>
            </Typography>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const createNumberOfPrepositions = () => {
    let color = "success";
    if (props.results.pos.IN > 0) {
      color = "warning";
    }

    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align="left">
          <Chip label={props.results.pos.IN} color={color} />
        </TableCell>
        <TableCell align="left">{"Number of Prepositions"}</TableCell>
        <TableCell align="left">
          {
            "A preposition is a word like “in, of, like”. The more of these present, the more difficult the item might be for ELLs. "
          }
          {props.results.pos.IN > 0 && (
            <Typography>
              {'>'}
              <Box fontWeight="bold" display="inline" bgcolor="warning">
                {` ${props.results.pos_words.IN.join(", ")}`}
              </Box>
            </Typography>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const createNumberOfPersonalPronouns = () => {
    let color = "success";
    if (props.results.pos.PRP > 0) {
      color = "warning";
    }

    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align="left">
          <Chip label={props.results.pos.PRP} color={color} />
        </TableCell>
        <TableCell align="left">{"Number of Personal Pronouns"}</TableCell>
        <TableCell align="left">
          {
            "A personal pronoun is a word like “I, he, she, it.” The more of these present, the more difficult the item might be for ELLs. "
          }
          {props.results.pos.PRP > 0 && (
            <Typography>
              {'>'}
              <Box fontWeight="bold" display="inline">
                {` ${props.results.pos_words.PRP.join(", ")}`}
              </Box>
            </Typography>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const createNumberOfPossessivePronouns = () => {
    let color = "success";
    if (props.results.pos.PRP$ > 0) {
      color = "warning";
    }

    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align="left">
          <Chip label={props.results.pos.PRP$} color={color} />
        </TableCell>
        <TableCell align="left">{"Number of Possessive Pronouns"}</TableCell>
        <TableCell align="left">
          {
            "A possessive pronoun is a word like “my, his, theirs”. The more of these present, the more difficult the item might be for ELLs."
          }
          {props.results.pos.PRP$ > 0 && (
            <Typography>
              {'>'}
              <Box fontWeight="bold" display="inline">
                {` ${props.results.pos_words.PRP$.join(", ")}`}
              </Box>
            </Typography>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const createNumberOfAdverbs = () => {
    let color = "success";
    if (props.results.pos.RB > 0) {
      color = "warning";
    }

    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align="left">
          <Chip label={props.results.pos.RB} color={color} />
        </TableCell>
        <TableCell align="left">{"Number of Adverbs"}</TableCell>
        <TableCell align="left">
          {
            "An adverb is a word like “usually, good, however”. The more of these present, the more difficult the item might be for ELLs."
          }
          {props.results.pos.RB > 0 && (
            <Typography>
              {'>'}
              <Box fontWeight="bold" display="inline">
                {` ${props.results.pos_words.RB.join(", ")}`}
              </Box>
            </Typography>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const createNumberOfAdjectives = () => {
    let color = "success";
    if (props.results.pos.JJ > 0) {
      color = "warning";
    }

    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align="left">
          <Chip label={props.results.pos.JJ} color={color} />
        </TableCell>
        <TableCell align="left">{"Number of Adjectives"}</TableCell>
        <TableCell align="left">
          {
            "An adjective is a word like ”large, elegant, wonderful”. The more of these present, the more difficult the item might be for ELLs."
          }
          {props.results.pos.JJ > 0 && (
            <Typography>
              {'>'}
              <Box fontWeight="bold" display="inline">
                {` ${props.results.pos_words.JJ.join(", ")}`}
              </Box>
            </Typography>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const createNumberOfPastTenseVerbs = () => {
    let color = "success";
    if (props.results.pos.VBD > 0) {
      color = "warning";
    }

    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align="left">
          <Chip label={props.results.pos.VBD} color={color} />
        </TableCell>
        <TableCell align="left">{"Number of Past Tense Verbs"}</TableCell>
        <TableCell align="left">
          {
            "A past tense verb is a word like “took, swam, ate”. The more of these present, the more difficult the item might be for ELLs."
          }
          {props.results.pos.VBD > 0 && (
            <Typography>
              {'>'}
              <Box fontWeight="bold" display="inline">
                {` ${props.results.pos_words.VBD.join(", ")}`}
              </Box>
            </Typography>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const createNumberOfGerunds = () => {
    let color = "success";
    if (props.results.pos.VBG > 0) {
      color = "warning";
    }

    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align="left">
          <Chip label={props.results.pos.VBG} color={color} />
        </TableCell>
        <TableCell align="left">{"Number of Gerunds"}</TableCell>
        <TableCell align="left">
          {
            "A gerund is a word like “swimming, taking, eating”. The more of these present, the more difficult the item might be for ELLs."
          }
          {props.results.pos.VBG > 0 && (
            <Typography>
              {'>'}
              <Box fontWeight="bold" display="inline">
                {` ${props.results.pos_words.VBG.join(", ")}`}
              </Box>
            </Typography>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const createNumberOfPastParticiples = () => {
    let color = "success";
    if (props.results.pos.VBN > 0) {
      color = "warning";
    }

    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align="left">
          <Chip label={props.results.pos.VBN} color={color} />
        </TableCell>
        <TableCell align="left">{"Number of Past Participles"}</TableCell>
        <TableCell align="left">
          {
            "A past participle is a phrase like “have taken, have eaten”. The more of these present, the more difficult the item might be for ELLs."
          }
          {props.results.pos.VBN > 0 && (
            <Typography>
              {'>'}
              <Box fontWeight="bold" display="inline">
                {` ${props.results.pos_words.VBN.join(", ")}`}
              </Box>
            </Typography>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const createNumberOfPresentTenseSingularVerbsThatArent3rdPerson = () => {
    let color = "success";
    if (props.results.pos.VBP > 0) {
      color = "warning";
    }

    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align="left">
          <Chip label={props.results.pos.VBP} color={color} />
        </TableCell>
        <TableCell align="left">
          {"Number of Present Tense Singular Verbs That Aren’t 3rd Person"}
        </TableCell>
        <TableCell align="left">
          {
            "A present tense singular verb that isn’t third person are verb forms like, “I swim, you swim”. The more of these present, the more difficult the item might be for ELLs."
          }
          {props.results.pos.VBP > 0 && (
            <Typography>
              {'>'}
              <Box fontWeight="bold" display="inline">
                {` ${props.results.pos_words.VBP.join(", ")}`}
              </Box>
            </Typography>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const createNumberOfPresentTenseSingularVerbsThatAre3rdPerson = () => {
    let color = "success";
    if (props.results.pos.VBZ > 0) {
      color = "warning";
    }

    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align="left">
          <Chip label={props.results.pos.VBZ} color={color} />
        </TableCell>
        <TableCell align="left">
          {"Number of Present Tense Singular Verbs That Are 3rd Person"}
        </TableCell>
        <TableCell align="left">
          {
            "A present tense singular verb that is third person is a verb form like, “she swims”. The more of these present, the more difficult the item might be for ELLs."
          }
          {props.results.pos.VBZ > 0 && (
            <Typography>
              {'>'}
              <Box fontWeight="bold" display="inline">
                {` ${props.results.pos_words.VBZ.join(", ")}`}
              </Box>
            </Typography>
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <React.Fragment>
      {createReadability()}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="results">
          <TableBody>
            {createWordCount()}
            {createAcademicWords()}
            {createPolysemousWords()}
            {createPValue()}
            {createDistinctNumbers()}
            {createNumberOfDeterminers()}
            {createNumberOfPredeterminers()}
            {createNumberOfPrepositions()}
            {createNumberOfPersonalPronouns()}
            {createNumberOfPossessivePronouns()}
            {createNumberOfAdverbs()}
            {createNumberOfAdjectives()}
            {createNumberOfPastTenseVerbs()}
            {createNumberOfGerunds()}
            {createNumberOfPastParticiples()}
            {createNumberOfPresentTenseSingularVerbsThatArent3rdPerson()}
            {createNumberOfPresentTenseSingularVerbsThatAre3rdPerson()}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default QuestionAnalyzerResultComponent;
