import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Divider,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link, Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 2
        }}
      >
        <Button variant="inherit" component={Link} to={"/"}>
          About
        </Button>
        <Button variant="inherit" component={Link} to={"/math-item-parse"}>
          Math Item Parse
        </Button>
        <Button variant="inherit" component={Link} to={"/cv"}>
          CV
        </Button>
      </Box>
      <Divider />

      <Outlet />
    </>
  );
};

export default Layout;
