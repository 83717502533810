import { Email } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import GithubIcon from "../icons/GithubIcon";
import LinkedInIcon from "../icons/LinkedInIcon";
import maggieImg from "../resources/images/maggie.jpeg";

const AboutMePage = () => {
  return (
    <Grid
      sx={{
        display: { xs: "block", md: "inline-flex" },
        margin: "auto",
        maxWidth: "1200px",
      }}
      container
      spacing={2}
    >
      <Grid item xs={12} md={2}>
        <img
          src={maggieImg}
          alt="Maggie Beiting-Parrish"
          style={{ width: 128, height: 128, borderRadius: 128 / 2 }}
        />
        <Box m={2} />
        <Typography variant="title">Maggie Beiting-Parrish</Typography>
        <Box m={2} />
        <Button
          sx={{ textTransform: "none" }}
          fullWidth
          startIcon={<Email />}
          onClick={(event) =>
            (window.location.href = "mailto:magdalen.beiting@gmail.com")
          }
        >
          Email
        </Button>
        <Button
          sx={{ textTransform: "none" }}
          fullWidth
          startIcon={<LinkedInIcon />}
          onClick={(event) =>
            (window.location.href =
              "https://www.linkedin.com/in/magdalen-beiting-parrish/")
          }
        >
          LinkedIn
        </Button>
        <Button
          sx={{ textTransform: "none" }}
          fullWidth
          startIcon={<GithubIcon />}
          onClick={(event) =>
            (window.location.href =
              "https://github.com/mathgie")
          }
        >
          Github
        </Button>
      </Grid>
      <Grid item xs={12} md={10}>
        <Typography variant="h3" align="left">
          About Me
        </Typography>
        <Box m={2} />
        <Typography align="left">Hi! Welcome to my website!</Typography>
        <Box m={2} />
        <Typography align="left">
          I'm Maggie Beiting-Parrish, a PhD candidate in Educational Psychology
          with a focus on quantitative methods. I am broadly interested in
          equity in standardized testing with an emphasis on the intersection of
          language and psychometrics, especially how the language we use in
          educational materials differentially impacts English Language
          Learners.
        </Typography>
        <Box m={2} />
        <Typography align="left">
          My dissertation features a review of the language used in mathematics
          items from 35 sources of standardized accountability tests
          representing grades 3-8. The culmination of this work is a prototype
          mathematics parser, Math Item Parse, which you can find on this
          website under the "Math Item Parse" tab.
        </Typography>
        <Box m={2} />
        <Typography align="left">
          I have been a teacher, curriculum evaluator, and differentiated math
          curriculum developer. I have extensive cognitive interview/think-aloud
          experience, as well as experience with a broad range of statistical
          and psychometric analyses. I also have extensive qualitative research
          and mixed-methods experience.
        </Typography>
        <Box m={2} />
        <Typography align="left">
          Thank you so much for coming to my website and please reach out if you
          want to collaborate on any research projects!
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AboutMePage;
