import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AboutMePage from "./pages/AboutMePage";
import CvPage from "./pages/CvPage";
import Layout from "./pages/Layout";
import NoPage from "./pages/NoPage";
import QuestionAnalyzePage from "./pages/QuestionAnalyzePage";

const theme = createTheme({});

class App extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<AboutMePage />} />
                <Route path="math-item-parse" element={<QuestionAnalyzePage />} />
                <Route path="cv" element={<CvPage />} />
                <Route path="*" element={<NoPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    );
  }
}

export default App;
